import React, { useState } from "react";
import { Input, Form, Button, message, Modal } from "antd";
import Spinner from "../../components/spinner/Spinner";
import {
  getAuth,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import config from "../../config.json";

function LoginFrom({ props, onApprove }) {
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [forgetEmail, setForgetEmail] = useState("");
  const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

  const onFinish = (values) => {
    setLoading(true);
    const auth = getAuth();
    signInWithEmailAndPassword(auth, values.username, values.password)
      .then(() => {
        localStorage.setItem(config.isLoginSuccessfully, "true");
        setLoading(false);
        return onApprove();
      })
      .catch((error) => {
        const errorMessage = error.message;
        setLoading(false);
        switch (errorMessage) {
          case "Firebase: Error (auth/wrong-password).":
            messageApi.open({
              type: "error",
              content: "Incorrect password.",
            });
            return;
          case "Firebase: Error (auth/user-not-found).":
            messageApi.open({
              type: "error",
              content: "User doesn’t exist",
            });
            return;
          case "Firebase: Error (auth/network-request-failed.":
            messageApi.open({
              type: "error",
              content:
                "We are experiencing network issues, please check your internet connection and try again.",
            });
            return;
          case "Firebase: Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later. (auth/too-many-requests).":
            messageApi.open({
              type: "error",
              content:
                "Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.",
            });
            return;
          default:
            messageApi.open({
              type: "error",
              content: errorMessage,
            });
            break;
        }
      });
  };
  const onFinishFailed = (errorInfo) => {
    messageApi.open({
      type: "error",
      content: errorInfo.errorFields[0].errors[0],
    });
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOk = () => {
    setIsModalOpen(false);
    const auth = getAuth();
    sendPasswordResetEmail(auth, forgetEmail)
      .then(() => {
        messageApi.open({
          type: "success",
          content:
            "If you already have an account with Bearer, please check your email and follow the instructions to reset your password.",
        });
        setForgetEmail("");
        setLoading(false);
      })
      .catch((error) => {
        messageApi.open({
          type: "error",
          content: error.message,
        });
        setLoading(false);
      });
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {contextHolder}
      {loading && <Spinner />}
      <Form
        name="normal_login"
        className="login-form"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout="vertical"
      >
        <Form.Item
          label="Username"
          name="username"
          rules={[
            {
              required: true,
              message: "Username is required",
            },
            {
              type: "email",
              message: "Enter an Email address",
            },
          ]}
        >
          <Input size="large" />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: "Password is required",
            },
          ]}
        >
          <Input.Password size="large" />
        </Form.Item>
        <Form.Item className="forget-password-wrapper">
          <Button
            className="forget-password"
            onClick={() => setIsModalOpen(true)}
          >
            FORGOT PASSWORD?
          </Button>
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            Login
          </Button>
        </Form.Item>
      </Form>
      <Modal
        style={{ padding: 20 }}
        title="Forgot Password?"
        open={isModalOpen}
        onOk={() => {
          forgetEmail.match(isValidEmail) && setLoading(true);
          handleOk();
        }}
        onCancel={handleCancel}
      >
        <p>Your Email Address:</p>
        <Input
          style={{ marginTop: 20, marginBottom: 20 }}
          status={!forgetEmail.match(isValidEmail) && "error"}
          size="large"
          placeholder="Email"
          type="email"
          value={forgetEmail}
          onChange={(e) => setForgetEmail(e.target.value)}
        />
      </Modal>
    </>
  );
}
export default LoginFrom;
