import React, { useState, useEffect } from "react";
import { Col, Typography, Checkbox, Input, Space, Select } from "antd";

import Autocomplete from "react-google-autocomplete";
import "./googleMapAutoComplete.scss";
function GoogleMapAutoComplete({ props, label, data, onAddressDataChange }) {
  const [unit, setUnit] = useState(data.unit_no);
  const [Apt, setApt] = useState(data.apt_no);
  const [state, setState] = useState(data.state);
  const [city, setCity] = useState(data.region_area);
  const [streetType, setStreetType] = useState(data.street_type);
  const [streetName, setStreetName] = useState(data.street_name);
  const [streetNumber, setStreetNumber] = useState(data.street_no);
  const [postCode, setPostCode] = useState(data.post_code);
  const [isAuto, setIsAuto] = useState(false);
  const [placeholder, setPlaceholder] = useState(
    "To search an address, just tick the checkbox"
  );

  useEffect(() => {
    onAddressDataChange([
      data.unit_no,
      data.apt_no,
      data.state,
      data.region_area,
      data.street_name,
      data.street_no,
      data.street_type,
      data.post_code,
      isAuto,
    ]);
  }, []);

  const onAddressCheckChange = (e) => {
    setIsAuto(e.target.checked);
    if (e.target.checked) {
      setUnit("");
      setApt("");
      setState("");
      setCity("");
      setStreetName("");
      setStreetNumber("");
      setStreetType("");
      setPostCode("");
      onAddressDataChange(["", "", "", "", "", "", "", "", true]);
      setPlaceholder("Type and address to begin");
    } else {
      setPlaceholder("To search an address, just tick the checkbox");
      setUnit(data.unit_no);
      setApt(data.apt_no);
      setState(data.state);
      setCity(data.region_area);
      setStreetName(data.street_name);
      setStreetNumber(data.street_no);
      setStreetType(data.street_type);
      setPostCode(data.post_code);
      onAddressDataChange([
        data.unit_no,
        data.apt_no,
        data.state,
        data.region_area,
        data.street_name,
        data.street_no,
        data.street_type,
        data.post_code,
        false,
      ]);
    }
  };

  const handleStateChange = (value) => {
    setState(value);
    onAddressDataChange([
      unit,
      Apt,
      value,
      city,
      streetName,
      streetNumber,
      streetType,
      postCode,
      isAuto,
    ]);
  };

  return (
    <>
      <Col xs={24} sm={24} md={24} lg={24}>
        <Space.Compact direction="vertical" block>
          <Typography.Text>{label}</Typography.Text>
        </Space.Compact>
      </Col>
      <Col xs={24} sm={24} md={12} lg={8} xl={6}>
        <Checkbox
          onChange={onAddressCheckChange}
          className="address-search"
          checked={isAuto}
        >
          Address search
        </Checkbox>
      </Col>
      <Col xs={24} sm={24} md={12} lg={16} xl={18}>
        <Space.Compact
          size="large"
          direction="vertical"
          block
          className="componnet-wrapper"
        >
          <Autocomplete
            className="google-auto-compelete"
            apiKey={process.env.REACT_APP_FIREBASE_API_KEY}
            placeholder={placeholder}
            style={{
              backgroundColor: "#FFF",
              border: "none",
              border: "1px solid #d9d9d9",
              borderRadius: 5,
              height: 35,
              padding: 5,
              outline: "none",
              marginTop: "4px",
              fontFamily: "g",
              fontSize: "16px",
            }}
            onPlaceSelected={(place) => {
              setIsAuto(true);
              setPostCode("");
              setStreetName("");
              setStreetNumber("");
              setCity("");
              setState("");
              setUnit("");
              setStreetType("");
              setPostCode(extractPostCode(place));
              setStreetName(extractStreetName(place));
              setStreetNumber(extractStreetNumber(place));
              setCity(extractCity(place));
              setState(extractState(place));
              setUnit(extractUnitNumber(place));
              onAddressDataChange([
                extractUnitNumber(place),
                Apt,
                extractState(place),
                extractCity(place),
                extractStreetName(place),
                extractStreetNumber(place),
                "",
                extractPostCode(place),
                true,
              ]);
            }}
            disabled={!isAuto}
            options={{
              types: [],
              bounds: {
                east: 145.597504,
                north: -37.572079,
                south: -38.608128,
                west: 144.572079,
              },
              strictBounds: true,
              componentRestrictions: { country: "au" },
            }}
          />
        </Space.Compact>
      </Col>
      <Col xs={24} sm={24} md={8} lg={8}>
        <Input
          disabled={isAuto}
          size="large"
          placeholder="Apt #"
          value={Apt}
          onChange={(e) => {
            onAddressDataChange([
              unit,
              e.target.value,
              state,
              city,
              streetName,
              streetNumber,
              streetType,
              postCode,
              isAuto,
            ]);
            setApt(e.target.value);
          }}
        />
      </Col>
      <Col xs={24} sm={24} md={8} lg={8}>
        <Input
          disabled={isAuto}
          size="large"
          placeholder="Unit #"
          value={unit}
          onChange={(e) => {
            setUnit(e.target.value);
            onAddressDataChange([
              e.target.value,
              Apt,
              state,
              city,
              streetName,
              streetNumber,
              streetType,
              postCode,
              isAuto,
            ]);
          }}
        />
      </Col>
      <Col xs={24} sm={24} md={8} lg={8}>
        <Input
          disabled={isAuto}
          size="large"
          placeholder="Street #"
          value={streetNumber}
          status={streetNumber.trim() === "" && "error"}
          onChange={(e) => {
            setStreetNumber(e.target.value);
            onAddressDataChange([
              unit,
              Apt,
              state,
              city,
              streetName,
              e.target.value,
              streetType,
              postCode,
              isAuto,
            ]);
          }}
        />
      </Col>
      <Col xs={24} sm={24} md={16} lg={16}>
        <Input
          disabled={isAuto}
          size="large"
          placeholder="Street Name"
          status={streetName.trim() === "" && "error"}
          value={streetName}
          onChange={(e) => {
            setStreetName(e.target.value);
            onAddressDataChange([
              unit,
              Apt,
              state,
              city,
              e.target.value,
              streetNumber,
              streetType,
              postCode,
              isAuto,
            ]);
          }}
        />
      </Col>
      <Col xs={24} sm={24} md={8} lg={8}>
        <Input
          disabled={isAuto}
          size="large"
          placeholder="Street Type"
          value={streetType}
          status={!isAuto && streetType.trim() === "" ? "error" : ""}
          onChange={(e) => {
            setStreetType(e.target.value);
            onAddressDataChange([
              unit,
              Apt,
              state,
              city,
              streetName,
              streetNumber,
              e.target.value,
              postCode,
              isAuto,
            ]);
          }}
        />
      </Col>
      <Col xs={24} sm={24} md={8} lg={8}>
        <Input
          disabled={isAuto}
          size="large"
          placeholder="City / Area"
          value={city}
          status={city.trim() === "" && "error"}
          onChange={(e) => {
            setCity(e.target.value);
            onAddressDataChange([
              unit,
              Apt,
              state,
              e.target.value,
              streetName,
              streetNumber,
              streetType,
              postCode,
              isAuto,
            ]);
          }}
        />
      </Col>
      <Col xs={24} sm={24} md={8} lg={8}>
        <Select
          placeholder="State"
          size="large"
          disabled={isAuto}
          className="select-state-auto-complete"
          defaultValue={state}
          onChange={handleStateChange}
          status={state === "" && "error"}
          options={[
            {
              value: "ACT",
              label: "ACT",
            },
            {
              value: "NSW",
              label: "NSW",
            },
            {
              value: "NT",
              label: "NT",
            },
            {
              value: "QLD",
              label: "QLD",
            },
            {
              value: "SA",
              label: "SA",
            },
            {
              value: "TAS",
              label: "TAS",
            },
            {
              value: "VIC",
              label: "VIC",
            },
            {
              value: "WA",
              label: "WA",
            },
          ]}
        />
      </Col>
      <Col xs={24} sm={24} md={8} lg={8}>
        <Input
          disabled={isAuto}
          size="large"
          placeholder="Area Code"
          status={postCode.trim() === "" && "error"}
          value={postCode}
          onChange={(e) => {
            setPostCode(e.target.value);
            onAddressDataChange([
              unit,
              Apt,
              state,
              city,
              streetName,
              streetNumber,
              streetType,
              e.target.value,
              isAuto,
            ]);
          }}
          type="number"
        />
      </Col>
    </>
  );
}
export default GoogleMapAutoComplete;

export function extractPostCode(place) {
  const postalCode = place.address_components.find(
    (p) => p.types[0] === "postal_code"
  );

  return postalCode ? postalCode.long_name : "";
}
export function extractStreetName(place) {
  const streetName = place.address_components.find(
    (p) => p.types[0] === "route"
  );

  return streetName ? streetName.long_name : "";
}
export function extractStreetNumber(place) {
  const streetNumber = place.address_components.find(
    (p) => p.types[0] === "street_number"
  );
  return streetNumber ? streetNumber.long_name : "";
}
export function extractCity(place) {
  const city = place.address_components.find((p) => p.types[0] === "locality");
  return city ? city.long_name : "";
}
export function extractState(place) {
  const state = place.address_components.find(
    (p) => p.types[0] === "administrative_area_level_1"
  );

  return state ? state.short_name : "";
}
export function extractUnitNumber(place) {
  const unitNumber = place.address_components.find(
    (p) => p.types[0] === "subpremise"
  );
  return unitNumber ? unitNumber.short_name : "";
}
