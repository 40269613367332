import whatsApp from "../../assets/images/whatsappLogo.svg";
import config from "../../config.json";

const WhatsAppFeedBack = () => {
  const whatsappLiveSupportNumber = localStorage.getItem(
    config.whatsappLiveSupportNumber
  );

  return (
    <a
      className="whats-app-live-chat"
      href={whatsappLiveSupportNumber}
      target="_blank"
      rel="noreferrer"
    >
      <img src={whatsApp} alt="rider" />
    </a>
  );
};

export default WhatsAppFeedBack;
