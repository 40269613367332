import React, { useState } from "react";
import logo from "../../assets/images/bearer-logo-footer.svg";
import emailLogo from "../../assets/images/email-newletter.svg";
import aus from "../../assets/images/bearer-logo-aust.svg";
import instagram from "../../assets/images/instagram-footer.png";
import facebook from "../../assets/images/facebook-footer.png";
import twitter from "../../assets/images/twitter-footer.png";
import linkedin from "../../assets/images/linkedin-footer.png";
import "./footer.scss";
import config from "../../config.json";
import * as footerServicve from "./footerServices";
import { Col, Row, Input } from "antd";
import { message } from "antd";
import Spinner from "../spinner/Spinner";
function Footer() {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  let baseUrl = window.localStorage.getItem(config.bearerWebsiteBaseUrl);

  const handleNewsFeed = async () => {
    const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    if (!regex.test(email)) {
      messageApi.open({
        type: "error",
        content: "Email address is not valid!",
      });
      return;
    }
    setLoading(true);
    try {
      const result = await footerServicve.websiteNewsletter(email);
      if (result) {
        setLoading(false);
        switch (result.data.code) {
          case 200:
            messageApi.open({
              type: "success",
              content: result.data.message,
            });
            setEmail("");
            return;
          default:
        }
      }
    } catch (error) {
      messageApi.open({
        type: "error",
        content: error,
      });
      setLoading(false);
    }
  };

  return (
    <>
      {isLoading && <Spinner />}
      {contextHolder}
      <div className="footer">
        <div className="logo-wrapper">
          <Row justify="center" align="middle" gutter={[48, 16]}>
            <Col xs={24} sm={24} md={7} lg={7}>
              <a href={baseUrl} target="_blank" rel="noreferrer">
                <img src={logo} className="logo" alt="logo" />
              </a>
            </Col>
            <Col xs={24} sm={24} md={5} lg={5}></Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <p>Parcels are closer to their destinations than ever!</p>
            </Col>
          </Row>
        </div>
        <div className="middle-footer-info">
          <Row justify="center" align="middle" gutter={[48, 16]}>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Row>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <ul>
                    <li>
                      <a href={baseUrl} target="_blank" rel="noreferrer">
                        Bearer for Everyone
                      </a>
                    </li>
                    <li>
                      <a
                        href={baseUrl + "/Business"}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Bearer for Business
                      </a>
                    </li>
                    <li>
                      <a
                        href={baseUrl + "/Bearers"}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Become a Bearer
                      </a>
                    </li>
                    <li>
                      <a
                        href={baseUrl + "/About"}
                        target="_blank"
                        rel="noreferrer"
                      >
                        About Bearer
                      </a>
                    </li>
                    <li>
                      <a
                        href={baseUrl + "/Blog"}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Bearer Blog
                      </a>
                    </li>
                  </ul>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <ul>
                    <li>
                      <a
                        href={baseUrl + "/FAQs?userType=bearers"}
                        target="_blank"
                        rel="noreferrer"
                      >
                        FAQs
                      </a>
                    </li>
                    <li>
                      <a
                        href={baseUrl + "/Support"}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Support
                      </a>
                    </li>
                    <li>
                      <a
                        href={baseUrl + "/Packaging"}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Packaging
                      </a>
                    </li>
                    <li>
                      <a
                        href={baseUrl + "/Contact"}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Contact Us
                      </a>
                    </li>
                    {/* <li>
                    <a
                        href={baseUrl + "/Packaging"}
                        target="_blank"
                        rel="noreferrer"
                      >Downloads</a>
                    </li> */}
                  </ul>
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <div className="newsletter">
                <p>Subscribe to our Newsletter to get the latest updates</p>
                <div className="newslette-input-wrapper">
                  <img src={emailLogo} alt="email-logo" />
                  <Input
                    type="String"
                    name="profileFullName"
                    value={email}
                    error={emailError}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your email"
                  />
                  <button
                    className="subscribe"
                    onClick={() => handleNewsFeed()}
                  >
                    Subscribe
                  </button>
                </div>
                <div className="social-wrapper">
                  <div className="social-link">
                    <ul className="social">
                      <li>
                        <a
                          href="https://www.instagram.com/Bearer.AU/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img src={instagram} alt="" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.facebook.com/Bearer.AU"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img src={facebook} alt="" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://twitter.com/Bearer_AU"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img src={twitter} alt="" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.linkedin.com/company/bearer-au/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img src={linkedin} alt="" />
                        </a>
                      </li>
                    </ul>
                    <div className="privacy">
                      <ul>
                        <li>
                          <a
                            href={baseUrl + "/Legal"}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Legal
                          </a>
                        </li>
                        <li>
                          <a
                            href={baseUrl + "/Legal/PrivacyPolicy"}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Privacy Policy
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="aus-logo">
                    <img src={aus} className="aus" alt="logo" />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="cite">
          <p>
            © 2024 Bearer | SeyCo Pty Ltd. <span>ABN: 64 626 871 225</span>
            <span>All rights reserved</span>
          </p>
        </div>
      </div>
    </>
  );
}

export default Footer;
