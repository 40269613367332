import React from "react";
import { Button, Result } from "antd";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";

function Page404(props) {
  return (
    <>
      <Header />
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <Button
            onClick={() => {
              props.history.push("/");
            }}
            type="primary"
          >
            Back Home
          </Button>
        }
      />
      <Footer />
    </>
  );
}
export default Page404;
