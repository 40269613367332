import React, { useEffect, useState } from "react";
import { message, Modal } from "antd";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { QuestionCircleFilled } from "@ant-design/icons";
import profileTooltip from "../../assets/images/profileTooltip.jpeg";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

import profile from "../../assets/images/profile.png";
import Spinner from "../spinner/Spinner";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { compressImage, log } from "../../utils/helper/Utils";

function UploadAvatarPhoto({ props, avatarUrl }) {
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [imageUrlBeforeCrop, setImageUrlBeforeCrop] = useState();
  const [imageServerUrl, setImageServerUrl] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const storagePhoto = getStorage();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cropper, setCropper] = useState();

  const handleOk = () => {
    setIsModalOpen(false);
    if (typeof cropper !== "undefined") {
      handleChangeAvatarChange(cropper.getCroppedCanvas().toDataURL());
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        return props.history.replace("/login");
      } else {
        setImageServerUrl(
          "riderUserAvatars/" + user.uid + "/" + user.uid + ".JPEG"
        );

        const starsRef = ref(storagePhoto, avatarUrl);
        downloadProfileImage(starsRef);
      }
    });
  }, []);

  const downloadProfileImage = (starsRef) => {
    getDownloadURL(starsRef)
      .then((url) => {
        setImageUrl(url);
        setLoading(false);
      })
      .catch((error) => {
        log(error);
      });
  };

  const handleChangeAvatarChange = async (file) => {
    const image = file;
    if (!image) return;
    const compressedImage = await compressImage(image);
    if (!compressedImage) return;
    setLoading(true);
    try {
      const metadata = {
        contentType: "image/jpeg",
      };
      const storageRef = ref(storagePhoto, imageServerUrl);
      uploadBytes(storageRef, compressedImage, metadata).then(() => {
        messageApi.open({
          type: "success",
          content: "ِYour file has been uploaded successfully",
        });
        const starsRef = ref(storagePhoto, avatarUrl);
        downloadProfileImage(starsRef);
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
      messageApi.open({
        type: "error",
        content: error.message ? error.message : error,
      });
    }
  };

  const onChange = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    if (files.length < 1) {
      return;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImageUrlBeforeCrop(reader.result);
      setIsModalOpen(true);
    };
    reader.readAsDataURL(files[0]);
  };

  return (
    <>
      {loading && <Spinner />}
      <div className="avatar-uploader-wrapper">
        {contextHolder}
        <div className="avatar-wrapper">
          <span className="tooltip-avatar">
            <QuestionCircleFilled
              className="tooltip-avatar-icon"
              style={{ fontSize: 25 }}
            />
            <img src={profileTooltip} />
          </span>
          <div className="avatar-image">
            <img src={imageUrl !== "" ? imageUrl : profile} alt="avatar" />
            <input
              id="files"
              type="file"
              accept="image/*"
              onChange={onChange}
            />
          </div>
        </div>
      </div>
      <Modal
        title="Crop Image"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Cropper
          style={{ height: 400, width: "100%" }}
          dragMode={"move"}
          initialAspectRatio={1}
          src={imageUrlBeforeCrop}
          cropBoxResizable={false}
          background={false}
          onInitialized={(instance) => {
            setCropper(instance);
          }}
        />
      </Modal>
    </>
  );
}
export default UploadAvatarPhoto;
