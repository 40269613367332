import React, { useEffect, useState } from "react";
import { Modal, message } from "antd";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { QuestionCircleFilled } from "@ant-design/icons";
import profileTooltip from "../../assets/images/profileTooltipId.png";
import { compressImage } from "../../utils/helper/Utils";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Spinner from "../spinner/Spinner";

function UploadCardId({ props, photoIdUrl, updateCardUrl, isChangePhoto }) {
  const [loading, setLoading] = useState(false);
  const [imageUrlPhotoId, setImageUrlPhotoId] = useState();
  const storagePhotoId = getStorage();
  const [messageApi, contextHolder] = message.useMessage();
  const auth = getAuth();
  const [imageUrlBeforeCrop, setImageUrlBeforeCrop] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cropper, setCropper] = useState();

  const handleOk = () => {
    setIsModalOpen(false);
    if (typeof cropper !== "undefined") {
      handleChangeAvatarChange(cropper.getCroppedCanvas().toDataURL());
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        return props.history.replace("/login");
      } else {
        isChangePhoto(false);
        const starsRef = ref(storagePhotoId, photoIdUrl);
        downloadPhotoIdImage(starsRef);
      }
    });
  }, []);

  function unixTimestamp(date = Date.now()) {
    return Math.floor(date / 1000);
  }

  const downloadPhotoIdImage = (starsRef) => {
    getDownloadURL(starsRef)
      .then((url) => {
        setImageUrlPhotoId(url);
        setLoading(false);
      })
      .catch((error) => {
        messageApi.open({
          type: "error",
          content: error.message ? error.message : "Something went wrong",
        });
        setLoading(false);
      });
  };

  const onChange = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    if (files.length < 1) {
      return;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImageUrlBeforeCrop(reader.result);
      setIsModalOpen(true);
    };
    reader.readAsDataURL(files[0]);
  };

  const handleChangeAvatarChange = async (file) => {
    const image = file;
    if (!image) return;
    const compressedImage = await compressImage(image);
    if (!compressedImage) return;
    setLoading(true);
    try {
      const metadata = {
        contentType: "image/jpeg",
      };
      const timestamp = unixTimestamp();
      const imageUrl =
        "idPhotoCard/" +
        auth.currentUser.uid +
        "/temporary/" +
        timestamp +
        ".JPEG";

      const storageRef = ref(storagePhotoId, imageUrl);
      uploadBytes(storageRef, compressedImage, metadata).then(() => {
        messageApi.open({
          type: "success",
          content: "ِYour file has been uploaded successfully",
        });
        updateCardUrl(imageUrl);
        isChangePhoto(true);
        const starsRef = ref(storagePhotoId, imageUrl);
        downloadPhotoIdImage(starsRef);
      });
    } catch (error) {
      setLoading(false);
      messageApi.open({
        type: "error",
        content: error.message ? error.message : error,
      });
    }
  };

  return (
    <>
      {loading && <Spinner />}
      {contextHolder}
      <div className="photo-id-uploader-wrapper">
        <span className="tooltip-photo-id">
          <QuestionCircleFilled
            className="tooltip-avatar-icon"
            style={{ fontSize: 25 }}
          />
          <img src={profileTooltip} />
        </span>
        <div className="avatar-uploader-photo-id-image">
          <img src={imageUrlPhotoId} alt="avatar" />
          <input id="files" type="file" accept="image/*" onChange={onChange} />
        </div>
      </div>
      <Modal
        title="Crop Image"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Cropper
          style={{ height: 400, width: "100%" }}
          dragMode={"move"}
          initialAspectRatio={8.5 / 5.5}
          src={imageUrlBeforeCrop}
          cropBoxResizable={false}
          background={false}
          onInitialized={(instance) => {
            setCropper(instance);
          }}
        />
      </Modal>
    </>
  );
}
export default UploadCardId;
