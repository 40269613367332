import { getValue, fetchAndActivate } from "firebase/remote-config";

import { remoteConfig } from "./index";
import config from "./config.json";
import { log } from "./utils/helper/Utils";

export const getRemoteConfigData = async () => {
  window.localStorage.clear()
  try {
    remoteConfig.settings.minimumFetchIntervalMillis = 0;
    await fetchAndActivate(remoteConfig).then(() => {
      const bearerWebsiteBaseUrl = getValue(remoteConfig, config.bearerWebsiteBaseUrl).asString();
      window.localStorage.setItem(config.bearerWebsiteBaseUrl, bearerWebsiteBaseUrl);
      log("bearerWebsiteBaseUrl:" + bearerWebsiteBaseUrl);
      // ----------------------------------------------------------------
      const ridersDocumentPortalUrl = getValue(remoteConfig, config.ridersDocumentPortalUrl).asString();
      window.localStorage.setItem(config.ridersDocumentPortalUrl, ridersDocumentPortalUrl);
      log("ridersDocumentPortalUrl:" + ridersDocumentPortalUrl);
      // ----------------------------------------------------------------
      const whatsappLiveSupportNumber = getValue(remoteConfig, config.whatsappLiveSupportNumber).asString();
      window.localStorage.setItem(config.whatsappLiveSupportNumber, whatsappLiveSupportNumber);
      log("whatsappLiveSupportNumber:" + whatsappLiveSupportNumber);
      // ----------------------------------------------------------------
      const environment = getValue(remoteConfig, config.environment).asString();
      window.localStorage.setItem(config.environment, environment);
      log("environment:" + environment);
      //----------------------------------------------------------------
      const latestTcPpVersionRiders = getValue(remoteConfig, config.latestTcPpVersionRiders).asString();
      window.localStorage.setItem(config.latestTcPpVersionRiders, latestTcPpVersionRiders);
      log("latestTcPpVersionRiders:" + latestTcPpVersionRiders);
      //----------------------------------------------------------------
      const riderPpUrl = getValue(remoteConfig, config.riderPpUrl).asString();
      window.localStorage.setItem(config.riderPpUrl, riderPpUrl);
      log("riderPpUrl:" + riderPpUrl);
      //----------------------------------------------------------------
      const riderTcUrl = getValue(remoteConfig, config.riderTcUrl).asString();
      window.localStorage.setItem(config.riderTcUrl, riderTcUrl);
      log("riderTcUrl:" + riderTcUrl);
      //----------------------------------------------------------------
      const riderLandingpageUrl = getValue(remoteConfig, config.riderLandingpageUrl).asString();
      window.localStorage.setItem(config.riderLandingpageUrl, riderLandingpageUrl);
      log("riderLandingpageUrl:" + riderLandingpageUrl);
      //----------------------------------------------------------------
      const bearerCloudfunctionsIsRunning = getValue(remoteConfig, config.bearerCloudfunctionsIsRunning).asString();
      window.localStorage.setItem(config.bearerCloudfunctionsIsRunning, bearerCloudfunctionsIsRunning);
      log("bearerCloudfunctionsIsRunning:" + bearerCloudfunctionsIsRunning);
      //----------------------------------------------------------------
      const bearerCloudfunctionsStatusMessageText = getValue(remoteConfig, config.bearerCloudfunctionsStatusMessageText).asString();
      window.localStorage.setItem(config.bearerCloudfunctionsStatusMessageText, bearerCloudfunctionsStatusMessageText);
      log("bearerCloudfunctionsStatusMessageText:" + bearerCloudfunctionsStatusMessageText);
      //----------------------------------------------------------------
      const bearerCloudfunctionsStatusMessageButtonAction = getValue(remoteConfig, config.bearerCloudfunctionsStatusMessageButtonAction).asString();
      window.localStorage.setItem(config.bearerCloudfunctionsStatusMessageButtonAction, bearerCloudfunctionsStatusMessageButtonAction);
      log("bearerCloudfunctionsStatusMessageButtonAction:" + bearerCloudfunctionsStatusMessageButtonAction);
      //----------------------------------------------------------------
      const riderSupportWebpageUrl = getValue(remoteConfig, config.riderSupportWebpageUrl).asString();
      window.localStorage.setItem(config.riderSupportWebpageUrl, riderSupportWebpageUrl);
      log("riderSupportWebpageUrl:" + riderSupportWebpageUrl);
      //----------------------------------------------------------------
      const bearerCloudfunctionsActionUrl = getValue(remoteConfig, config.bearerCloudfunctionsActionUrl).asString();
      window.localStorage.setItem(config.bearerCloudfunctionsActionUrl, bearerCloudfunctionsActionUrl);
      log("bearerCloudfunctionsActionUrl:" + bearerCloudfunctionsActionUrl);
      //----------------------------------------------------------------
      const bearerRidersDocumentPortalIsRunning = getValue(remoteConfig, config.bearerRidersDocumentPortalIsRunning).asString();
      window.localStorage.setItem(config.bearerRidersDocumentPortalIsRunning, bearerRidersDocumentPortalIsRunning);
      log("bearerRidersDocumentPortalIsRunning:" + bearerRidersDocumentPortalIsRunning);
      //----------------------------------------------------------------
      const bearerRidersDocumentPortalStatusMessageText = getValue(remoteConfig, config.bearerRidersDocumentPortalStatusMessageText).asString();
      window.localStorage.setItem(config.bearerRidersDocumentPortalStatusMessageText, bearerRidersDocumentPortalStatusMessageText);
      log("bearerRidersDocumentPortalStatusMessageText:" + bearerRidersDocumentPortalStatusMessageText);
      //----------------------------------------------------------------
      const bearerRidersDocumentPortalActionUrl = getValue(remoteConfig, config.bearerRidersDocumentPortalActionUrl).asString();
      window.localStorage.setItem(config.bearerRidersDocumentPortalActionUrl, bearerRidersDocumentPortalActionUrl);
      log("bearerRidersDocumentPortalActionUrl:" + bearerRidersDocumentPortalActionUrl);
      //----------------------------------------------------------------
      const bearerRidersDocumentPortalStatusMessageButtonAction = getValue(remoteConfig, config.bearerRidersDocumentPortalStatusMessageButtonAction).asString();
      window.localStorage.setItem(config.bearerRidersDocumentPortalStatusMessageButtonAction, bearerRidersDocumentPortalStatusMessageButtonAction);
      log("bearerRidersDocumentPortalStatusMessageButtonAction:" + bearerRidersDocumentPortalStatusMessageButtonAction);
    });

  } catch (err) {
    log(err);
  }
};
