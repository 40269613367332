import { getFunctions, httpsCallable } from "firebase/functions";
import config from "../../config.json";
import { log } from "../../utils/helper/Utils";

export function documentsPortalLoginPermission() {
  const functions = getFunctions();
  const motorbike = httpsCallable(
    functions,
    config.documentsPortalLoginPermission
  );
  return motorbike();
}

export function documentsPortalUpdateIdPhotoCard(
  avatarUrl,
  cardUrl,
  isCardUploaded,
  signedName,
  ip
) {
  let data = {
    avatar_url: avatarUrl,
    id_photo_card_url: cardUrl,
    is_id_photo_card_uploaded: isCardUploaded,
    name_as_signed: signedName,
    ip: ip,
  };
  log(data);
  const functions = getFunctions();
  const documentsPortalUpdateIdPhotoCard = httpsCallable(
    functions,
    config.documentsPortalUpdateIdPhotoCard
  );
  return documentsPortalUpdateIdPhotoCard(data);
}

export function documentsPortalUpdateLegalDetails(
  legalName,
  abn,
  apt,
  unit,
  streetNo,
  streetName,
  streetType,
  regionArea,
  state,
  postCode,
  country,
  insuranceValidityDate,
  insurerCompany,
  insuranceNumber,
  certificateUrl,
  gst,
  nameAsSigned,
  ip,
  noInsuranceFile,
  insuranceType
) {
  let data = {
    legal_name: legalName,
    abn: abn,
    legal_address_segmented: {
      apt_no: apt,
      unit_no: unit,
      street_no: streetNo,
      street_name: streetName,
      street_type: streetType,
      region_area: regionArea,
      state: state,
      post_code: postCode,
      country: country,
    },

    public_liability_insurance_validity_date: insuranceValidityDate,
    public_liability_insurer_company: insurerCompany,
    public_liability_insurance_number: insuranceNumber,
    public_liability_insurance_certificate_url: certificateUrl,
    rider_registered_for_gst: gst,
    name_as_signed: nameAsSigned,
    ip: ip,
    public_liability_insurance_not_provided: noInsuranceFile,
    public_liability_insurance_type: insuranceType,
  };
  log(data);

  const functions = getFunctions();
  const documentsPortalUpdateLegalDetails = httpsCallable(
    functions,
    config.documentsPortalUpdateLegalDetails
  );
  return documentsPortalUpdateLegalDetails(data);
}

export function documentsPortalUpdateNCC(
  givenName,
  lastName,
  middleName,
  birthDate,
  gender,
  apt,
  unit,
  streetNo,
  streetName,
  streetType,
  regionArea,
  state,
  postCode,
  country,
  nameAsSigned,
  ip
) {
  let data = {
    given_name: givenName,
    last_name: lastName,
    middle_name: middleName,
    birth_date: birthDate,
    gender: gender,
    residential_address_segmented: {
      apt_no: apt,
      unit_no: unit,
      street_no: streetNo,
      street_name: streetName,
      street_type: streetType,
      region_area: regionArea,
      state: state,
      post_code: postCode,
      country: country,
    },
    name_as_signed: nameAsSigned,
    ip: ip,
  };
  log(data);

  const functions = getFunctions();
  const documentsPortalUpdateNCC = httpsCallable(
    functions,
    config.documentsPortalUpdateNCC
  );
  return documentsPortalUpdateNCC(data);
}

export function documentsPortalDispute(
  nameAsSigned,
  ip,
  messageText,
  documentURL
) {
  let data = {
    name_as_signed: nameAsSigned,
    ip: ip,
    riders_rejection_dispute_message_text: messageText,
    riders_rejection_dispute_document_url: documentURL,
  };
  log(data);

  const functions = getFunctions();
  const documentPortalDispute = httpsCallable(
    functions,
    config.documentsPortalDispute
  );
  return documentPortalDispute(data);
}
