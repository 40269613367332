import "./App.css";
import React, { useEffect, useState } from "react";

import { Route, Switch, Redirect } from "react-router-dom";
import Login from "./pages/login/Login";
import Main from "./pages/main/Main";
import Page404 from "./pages/page404/page404";
import * as service from "./homeService";
import config from "./config.json";
import { log } from "./utils/helper/Utils";
import { Modal } from "antd";
import setHotjar from "./scriptTags/hotjar";
import GoogleTagManager from "./scriptTags/googleTagManager";
import FacePixel from "./scriptTags/facePixel";

function App() {
  const isLogin = localStorage.getItem(config.isLoginSuccessfully);
  const [isData, setIsData] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const modalMessage = (message, confirmText) => {
    Modal.warning({
      title: "Oops!!!!",
      maskClosable: false,
      content: (
        <div>
          <p>{message}</p>
        </div>
      ),
      okText: confirmText,
      onOk() {
        if (confirmText === "Support") {
          window.open(localStorage.getItem(config.riderSupportWebpageUrl));
        } else {
          window.open(
            localStorage.getItem(config.bearerRidersDocumentPortalActionUrl)
          );
        }
      },
    });
  };

  const modalCloudfunctionsMessage = (message, confirmText) => {
    Modal.warning({
      title: "Oops!!!!",
      maskClosable: false,
      content: (
        <div>
          <p>{message}</p>
        </div>
      ),
      okText: confirmText,
      onOk() {
        if (confirmText === "Support") {
          window.open(localStorage.getItem(config.riderSupportWebpageUrl));
        } else {
          window.open(
            localStorage.getItem(config.bearerCloudfunctionsActionUrl)
          );
        }
      },
    });
  };

  const getData = async () => {
    try {
      await service.getRemoteConfigData();
      if (localStorage.getItem(config.environment) === "PRODUCTION") {
        setHotjar()
      }

      if (
        localStorage.getItem(config.bearerCloudfunctionsIsRunning) &&
        localStorage
          .getItem(config.bearerCloudfunctionsIsRunning)
          .toLowerCase()
          .includes("true")
      ) {
        if (
          localStorage.getItem(config.bearerRidersDocumentPortalIsRunning) &&
          localStorage
            .getItem(config.bearerRidersDocumentPortalIsRunning)
            .toLowerCase()
            .includes("true")
        ) {
          setIsData(true);
        } else {
          if (
            localStorage.getItem(
              config.bearerRidersDocumentPortalStatusMessageButtonAction
            ) &&
            localStorage
              .getItem(
                config.bearerRidersDocumentPortalStatusMessageButtonAction
              )
              .toLowerCase()
              .includes("support")
          ) {
            modalMessage(
              localStorage.getItem(
                config.bearerRidersDocumentPortalStatusMessageText
              ),
              "Support"
            );
          } else {
            modalMessage(
              localStorage.getItem(
                config.bearerRidersDocumentPortalStatusMessageText
              ),
              "Learn More"
            );
          }
        }
      } else {
        if (
          localStorage.getItem(
            config.bearerCloudfunctionsStatusMessageButtonAction
          ) &&
          localStorage
            .getItem(config.bearerCloudfunctionsStatusMessageButtonAction)
            .toLowerCase()
            .includes("support")
        ) {
          modalCloudfunctionsMessage(
            localStorage.getItem(config.bearerCloudfunctionsStatusMessageText),
            "Support"
          );
        } else {
          modalCloudfunctionsMessage(
            localStorage.getItem(config.bearerCloudfunctionsStatusMessageText),
            "Learn More"
          );
        }
      }
    } catch (err) {
      log(err);
    }
  };

  const handleRoute = () => {
    return (
      <>
        {localStorage.getItem(config.environment) === "PRODUCTION" && (
          <div>
            <GoogleTagManager />
            <FacePixel />
          </div>
        )}
        <Switch>
          <Route path="/home" component={Main} onEnter={getData} />
          <Route path="/login" component={Login} onEnter={getData} />
          <Route
            exact
            path="/"
            component={
              isLogin === "true"
                ? () => <Redirect to="/home" />
                : () => <Redirect to="/login" />
            }
          />
          <Route path="*" component={Page404} />
        </Switch>
      </>
    );
  };
  return <>{isData ? handleRoute() : null}</>;
}

export default App;
