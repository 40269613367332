import React from "react";
import { Row, Col } from "antd";
import "./header.scss";
import logo from "../../assets/images/bearer-logo.svg";
import config from "../../config.json";
function Header() {
  let baseUrl = window.localStorage.getItem(config.bearerWebsiteBaseUrl);
  return (
    <div className="header">
      <Row justify="center" align="middle">
        <Col xs={20} sm={20} md={3} xl={3}>
          <h1>
            <a href={baseUrl} target="_blank" rel="noreferrer">
              <img src={logo} alt="logo" />
            </a>
          </h1>
        </Col>
        <Col xs={20} sm={20} md={17} xl={17}>
          <ul>
            <li>
              <a href={baseUrl} target="_blank" rel="noreferrer">
                Bearer Home
              </a>
            </li>
            <li>.</li>
            <li>
              <a href={baseUrl + "/About"} target="_blank" rel="noreferrer">
                About us
              </a>
            </li>
            <li>.</li>
            <li>
              <a href={baseUrl + "/FAQs?userType=bearers"} target="_blank" rel="noreferrer">
                FAQs
              </a>
            </li>
          </ul>
        </Col>
      </Row>
    </div>
  );
}

export default Header;
