import React from "react";
import "./spinner.scss";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

const Spinner = () => {
  const antIcon = <LoadingOutlined style={{ fontSize: 80 }} spin />;

  return (
    <>
      <div className="spinner-outer-block">
        <div className="spinner-block">
          <div className="spinner-border" role="status">
            <Spin indicator={antIcon} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Spinner;
