import "./serverMessage.scss";
import { Typography } from "antd";

function ServerMessage({ props, data }) {
  const { Paragraph } = Typography;
  const reason =
    data.public_liability_insurance &&
    data.public_liability_insurance.invalidity_reason
      ? data.public_liability_insurance.invalidity_reason
      : "";
  return (
    <>
      <h3 className="bearer-info-title">Message from bearer support team</h3>
      <Paragraph className="bearer-info" style={{ whiteSpace: "pre-line" }}>
        {data.sign_up_documents_review_result_note}
      </Paragraph>
      {reason !== "" &&
        data.sign_up_stage === 8 &&
        data.sign_up_sub_stage === 2 && (
          <>
            <h3 className="bearer-info-title">Public Liability Insurance</h3>
            <Paragraph className="bearer-info">{reason}</Paragraph>
          </>
        )}
    </>
  );
}

export default ServerMessage;
