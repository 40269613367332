export const log = (any) => {
  if (process.env.NODE_ENV === "development") console.log(any);
};
export function abnValidator(myAbn) {
  let result = false;
  let ABN;
  try {
    ABN = myAbn;
  } catch (e) {
    ABN = "";
  }
  if (ABN.length === 11) {
    try {
      let control = new Array(11);
      control[0] = (Number(ABN[0], false) - 1) * 10;
      control[1] = Number(ABN[1], false);
      control[2] = Number(ABN[2], false) * 3;
      control[3] = Number(ABN[3], false) * 5;
      control[4] = Number(ABN[4], false) * 7;
      control[5] = Number(ABN[5], false) * 9;
      control[6] = Number(ABN[6], false) * 11;
      control[7] = Number(ABN[7], false) * 13;
      control[8] = Number(ABN[8], false) * 15;
      control[9] = Number(ABN[9], false) * 17;
      control[10] = Number(ABN[10], false) * 19;
      let total = 0;
      for (let i = 0; i < 11; i++) {
        total += control[i];
      }

      if (total % 89 === 0) result = true;
      else result = false;
    } catch {
      result = false;
    }
  } else result = false;
  return result;
}

export async function compressImage(imageSrc) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  if (!ctx) return null;

  const imageType = "image/JPEG";

  canvas.width = image.width;
  canvas.height = image.height;

  ctx.drawImage(image, 0, 0);

  return new Promise((resolve) => {
    canvas.toBlob(
      (file) => {
        resolve(file);
      },
      imageType,
      0.9
    );
  });
}

export const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });
