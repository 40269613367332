import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import theme from "./theme/theme";
import { ConfigProvider } from "antd";
import { BrowserRouter } from "react-router-dom";

import { initializeApp } from "firebase/app";
import firebaseConfig from "./firebase/firebaseConfig";
import { getStorage } from "firebase/storage";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { getRemoteConfig } from "firebase/remote-config";

const app = initializeApp(firebaseConfig);
getStorage(app);
export const remoteConfig = getRemoteConfig(app);

const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider(
    process.env.REACT_APP_FIREBASE_APPCHECK_KEY
  ),
  isTokenAutoRefreshEnabled: true,
});
export const appCheckToken = appCheck;

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <ConfigProvider theme={theme}>
      <App />
    </ConfigProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
